<template>
    <div>
      <el-main>
        <!-- 表单 -->
        <el-form class="el-form-search" label-width="140px">
          <el-form-item label="订单编号：">
            <el-input size="small" placeholder="" v-model="searchForm.nickname"></el-input>
          </el-form-item>
          <el-form-item label="技工姓名：">
            <el-input size="small" placeholder="" v-model="searchForm.nickname"></el-input>
          </el-form-item>
          <el-form-item label="联系电话：">
            <el-input size="small" placeholder="" v-model="searchForm.nickname"></el-input>
          </el-form-item>
          <el-form-item label="技师类型：">
            <el-select size="small" v-model="searchForm.role_type" clearable>
              <el-option label="全部" value=""></el-option>
              <el-option v-for="item in role_type" :key="item.val" :label="item.label" :value="item.val"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="付款时间：">
            <el-date-picker
              size="small"
              v-model="searchForm.create_time"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label=" " label-width="60px">
            <el-button type="primary" size="small" @click="getList">搜索</el-button>
            <!-- <el-button size="small">导出</el-button> -->
            <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
          </el-form-item>
        </el-form>

        <!-- 列表 -->
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
          <template slot="empty">
            <No />
          </template>
          <el-table-column label="订单编号" align="center" min-width="120">
            <template v-slot="{ row }">
              <div class="userInfo">
                <el-image :src="row.avatar || 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/beauty/defaultHeaderImg.png'"></el-image>
                <div class="flex1">{{ row.nickname }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="mobile" label="服务区域" min-width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.mobile || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="total_order_amount" label="技师姓名" align="center" min-width="120"></el-table-column>
          <el-table-column prop="total_order_count" label="联系电话" align="center" min-width="120"></el-table-column>
          <el-table-column prop="role_type" label="入驻保证金" align="center" min-width="130">
            <template v-slot="{ row }">{{ row.role_type | role_type_text(role_type) }}</template>
          </el-table-column>
          <el-table-column prop="card_item" label="付款时间" align="center" min-width="150"></el-table-column>
        </el-table>
        <el-button @click="toRightsDetail()" type="text" size="small">详情</el-button>
        <!-- 翻页 -->
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
      </el-main>
    </div>
  </template>

  <script>
  import { getDateformat } from '@/util/getDate';
  import Paging from '@/components/paging';
  export default {
    components: {
      Paging,
    },
    data() {
      return {
        interval: '',
        key: new Date(),

        searchForm: {
          page: 1,
          rows: 10,
          nickname: '',
          mobile: '',
          create_time: '',
          role_type: '',
        },
        total_number: 0,
        role_type: [
          {
            val: '1',
            label: '普通用户',
          },
          {
            val: '2',
            label: '推广员',
          },
          {
            val: '3',
            label: '创业股东',
          },
        ],
        form: {
          radio: 1,
          commissionRate: 0.6,
          date1: '',
          date2: '',
        },
        url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        srcList: ['https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg', 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'],
      };
    },
    filters: {
      getDateformat(val) {
        if (val) {
          return getDateformat(val);
        } else {
          return '--';
        }
      },
      intervalLabel(val) {
        switch (val) {
          case 15:
            return '15分钟';
            break;
          case 30:
            return '30分钟';
            break;
          default:
            return val / 60 + '小时';
            break;
        }
      },
      role_type_text(val, role_type) {
        return role_type.find(i => i.val == val).label;
      },
    },
    created() {
      this.getList();
    },
    methods: {
      updateData(val, status) {
        if (status == 0) {
          this.searchForm.rows = val;
          this.getList();
        } else {
          this.searchForm.page = val;
          this.getList();
        }
      },
      cancelSearch() {
        this.searchForm = {
          page: 1,
          rows: 10,
          service_name: '',
          service_phone: '',
          create_time: '',
        };
        this.getList();
      },
      getList() {
        let data = Object.assign({}, this.searchForm);
        if (data.create_time) {
          data.create_time[0] = data.create_time[0] / 1000;
          data.create_time[1] = data.create_time[1] / 1000;
        }
        this.$axios.post(this.$api.beauty.userList, data).then(res => {
          if (res.code == 0) {
            this.total_number = res.result.total_number;
            this.key = new Date();
            this.list = res.result.list;
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      toDetail() {
        this.$router.push({ path: '/user/repairAddSettled'});
      },
      toRightsDetail() {
        this.$router.push({ path: '/user/repairSettledDetail'});
      },
      delItem(id) {
        this.$confirm('确定要删除此用户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$axios.post(this.$api.beauty.delUser, { id }).then(res => {
              if (res.code == 0) {
                this.$message.success('删除成功');
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {});
      },
    },
  };
  </script>

  <style lang="less" scoped>
  .el-main {
    background: #fff;
  }
  .el-table {
    .userInfo {
      display: flex;
      align-items: center;

      .el-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
  .row {
    display: flex;
    align-items: center;

    .icon {
      font-size: 18px;
      margin-left: 10px;
      color: #409eff;
      margin-top: 2px;
    }
  }

  .el-form-search {
    margin-bottom: 25px;
  }

  .formBtn {
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .cardUl {
    width: 100%;
    background-color: #fff;
    margin-top: 50px;
    padding: 50px 0;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      width: 45%;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 14px;
        color: gray;
      }
    }

    .cardLi {
      width: 80%;
      border: 20px solid #bbbbbb;
      position: relative;
      margin-bottom: 50px;

      .cardLiTil {
        background-color: #f0eeee;
        color: gray;
        font-size: 18px;
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .cardLiTab {
        padding: 20px;
      }

      .cardTabPage {
        display: flex;
        justify-content: center;

        position: absolute;
        bottom: 20px;
        left: 40%;
      }
    }
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .rejection {
    width: 100%;
    background-color: #fff;
    border: 2px solid #000;

    .rejectionTil {
      padding: 10px 20px;
      border-bottom: 1px solid #ccc;

      span {
        background-color: #f0eeee;
      }
    }
    .rejectionTab {
      padding: 20px;
    }

    .rejectionPage {
      height: 50px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  </style>
